
//公共库
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { addOfficial, delOfficial, getOfficiallist, editOfficial } from "@/api/request/bulletin";

//组件
@Component({
  name: "Announcement",
  components: {
    VueEditor,
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //创建时调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取公告列表
    this.getList();
  }

  //----------------------------- 主界面 -----------------------------
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private tableKey: number = 0; //表格Key
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = true; // 列表加载
  private typeNameList: string[] = ["", "指定用户", "全部用户", "全部博主", "全部男用户", "全部女用户", "新注册男用户", "新注册女用户", "全部新注册用户"]; //类型名称列表

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 0,
    row: 20,

    //请求数据
    country: "", //归属国家
  };

  //获取公告列表
  private async getList() {
    //显示列表加载
    this.listLoading = true;

    //获取数据
    const { data } = await getOfficiallist(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏列表加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //发布公告
  private addHandler(): void {
    //重置参数
    this.resetParams();

    //隐藏界面
    this.noticeVisible = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //编辑公告
  private async editHandler(row: any) {
    //数据赋值
    this.sendParams = GFunc.deepClone(Object.assign({}, row));

    //目标ID列表
    if (row.ids !== "") {
      JSON.parse(row.ids).forEach((item: any) => {
        this.tags.push(String(item));
      });
    }

    //状态数据
    this.sendParams.jump_userid = String(this.sendParams.jump_userid); //跳转用户ID
    this.sendParams.popup_status = String(this.sendParams.popup_status); //登录弹窗状态

    //时间数据
    if (this.sendParams.begin_time_str && this.sendParams.end_time_str) this.$set(this.sendParams, "effectiveTime", [this.sendParams.begin_time_str, this.sendParams.end_time_str]);
    if (this.sendParams.popup_start_time_str && this.sendParams.popup_end_time_str) this.$set(this.sendParams, "time", [this.sendParams.popup_start_time_str, this.sendParams.popup_end_time_str]);

    //图片数据
    this.picFileList = row.cover == "" ? [] : [{ url: row.cover }];
    this.lPicFileList = row.image == "" ? [] : [{ url: row.image }];
    this.noticeVisible = true;
  }

  //删除公告
  private deleteHandler(row: any): void {
    this.$confirm(`您确定要删除${row.title} ？`, "警告", {
      type: "warning",
      cancelButtonText: this.$t("permission.cancel") as string,
      confirmButtonText: this.$t("permission.confirm") as string,
    })
      .then(async () => {
        //删除公告
        await delOfficial({ id: row.id });

        //显示提示
        this.$message.success("删除成功");

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //跳转类型
  private junpSceneStr(type: number): string {
    //返回数据
    if (type == 0) {
      return "不跳转";
    } else if (type == 1) {
      return "跳转充值界面";
    } else if (type == 2) {
      return "跳转个人主页";
    } else if (type == 3) {
      return "跳转任务界面";
    } else if (type == 4) {
      return "跳转广场界面";
    } else if (type == 5) {
      return "跳转到女神大赛主界面";
    } else if (type == 6) {
      return "跳转到申请博主页面";
    } else if (type == 7) {
      return "跳转内测服务详情页";
    } else {
      return "";
    }
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //---------------------------- 公告详情 ----------------------------
  //定义变量
  private detailDialog = false; //显示状态
  private detailContent = ""; //公告详情内容
  private detailSrc: string = ""; //公告详情图片
  private detailTitle: string = ""; //公告详情标题

  //隐藏公告详情
  private detailCancel(): void {
    //隐藏界面
    this.detailDialog = false;
  }

  //显示公告详情
  private gotoDetail(row: any): void {
    //数据赋值
    this.detailSrc = row.cover;
    this.detailTitle = row.title;
    this.detailContent = row.content;

    //显示界面
    this.detailDialog = true;
  }

  //---------------------------- 发布公告 ----------------------------
  //定义变量
  private tags: any = []; //发放目标
  private coverUrl: any = null; //公告图片地址
  private imageUrl: any = null; //列表图片地址
  private picFileList: any[] = []; //公告图片文件
  private lPicFileList: any[] = []; //列表图片文件
  private tagInputMore: string = ""; //批量添加ID文本
  private tagInput: string = ""; //用户ID文本
  private noticeVisible = false; //显示状态

  //用户类型
  private userTypeList: any = [
    { id: 1, name: "指定用户" },
    { id: 2, name: "全部用户" },
    { id: 3, name: "全部博主" },
    { id: 4, name: "全部男用户" },
    { id: 5, name: "全部女用户" },
    { id: 6, name: "新注册男用户" },
    { id: 7, name: "新注册女用户" },
    { id: 8, name: "全部新注册用户" },
  ];

  //跳转类型
  private jumpTypeList: any = [
    { id: 0, name: "不跳转" },
    { id: 1, name: "跳转充值界面" },
    { id: 2, name: "跳转个人主页" },
    { id: 3, name: "跳转任务界面" },
    { id: 4, name: "跳转广场界面" },
    { id: 5, name: "跳转到女神大赛主界面" },
    { id: 6, name: "跳转到申请博主页面" },
    { id: 7, name: "跳转内测任务详情页" },
  ];

  //发送数据
  private sendParams: any = {
    //图片变量
    url: "", //连接地址
    pics: "", //公告图片
    cover: "", //公告图片
    image: "", //列表图片

    //基本信息
    type: 1, //用户类型
    title: "", //公告标题
    country: "", //归属国家
    content: "", //公告内容
    id: undefined, //公告ID
    uids: undefined, //UID列表

    //有效时间
    end_time_str: "", //有效结束时间
    begin_time_str: "", //有效开始时间
    effectiveTime: [], //有效时间

    //状态变量
    jump_scene: 0, //跳转界面
    scene_params: "", //跳转参数
    jump_userid: "", //跳转用户ID
    popup_status: "0", //登录弹窗状态

    //弹窗时间
    popup_start_time_str: "", //弹窗结束时间
    popup_end_time_str: "", //弹窗开始时间
    time: [], //弹窗时间
  };

  //重置参数
  private resetParams() {
    //本地变量
    this.tags = [];
    this.coverUrl = null;
    this.imageUrl = null;
    this.picFileList = [];
    this.lPicFileList = [];

    //图片变量
    this.sendParams.url = ""; //连接地址
    this.sendParams.pics = ""; //公告图片
    this.sendParams.cover = ""; //公告图片
    this.sendParams.image = ""; //列表图片

    //基本信息
    this.sendParams.type = 1; //用户类型
    this.sendParams.title = ""; //公告标题
    this.sendParams.country = "MY"; //归属国家
    this.sendParams.content = ""; //公告内容
    this.sendParams.id = undefined; //公告ID
    this.sendParams.uids = undefined; //UID列表

    //有效时间
    this.sendParams.end_time_str = ""; //有效结束时间
    this.sendParams.begin_time_str = ""; //有效开始时间
    this.sendParams.effectiveTime = []; //有效时间

    //状态变量
    this.sendParams.jump_scene = 0; //跳转任务状态
    this.sendParams.scene_params = ""; //跳转参数
    this.sendParams.jump_userid = ""; //跳转用户ID
    this.sendParams.popup_status = "0"; //登录弹窗状态

    //弹窗时间
    this.sendParams.popup_end_time_str = ""; //弹窗结束时间
    this.sendParams.popup_start_time_str = ""; //弹窗开始时间
    this.sendParams.time = []; //弹窗时间
  }

  //选择图片连接
  private gotoPage(): void {
    //图片接连赋值
    if (this.picFileList.length) {
      this.sendParams.url = this.picFileList[0].response.data.images[0];
    }
    //提示上传公告图片
    else {
      this.$message.error("请先上传公告图片");
    }
  }

  //取消按钮
  private btnCancel(): void {
    //重置参数
    this.resetParams();

    //隐藏界面
    this.noticeVisible = false;
  }

  //发布按钮
  private async btnPublish() {
    //基本信息
    if (!this.tags.length && this.sendParams.type === 1) return this.$message.error("请您输入要发布的对象");
    if (!this.sendParams.country || this.sendParams.country == "") return this.$message.error("请选择归属国家");
    if (!this.sendParams.content || this.sendParams.content == "") return this.$message.error("请输入内容");
    if (!this.sendParams.title || this.sendParams.title == "") return this.$message.error("请输入标题");

    //图片配置
    if (!this.picFileList.length) return this.$message.error("请上传公告图片");

    //时间配置
    if (this.sendParams.time.length != 2) return this.$message.error("请选择弹窗时间");
    if (this.sendParams.effectiveTime.length != 2) return this.$message.error("请选择有效时间");

    //个人主页
    if (this.sendParams.jump_scene == 2) {
      if (this.sendParams.jump_userid == "") {
        return this.$message.error("请输入个人主页ID");
      } else {
        //数据处理
        if (/^[1-9]\d*$/.test(this.sendParams.jump_userid.trim()) == false) {
          return this.$message.error("请输入正确形式的ID");
        }
      }
    }

    //显示提示框
    this.$confirm(`确定要发布？`, "警告", {
      type: "warning",
      cancelButtonText: this.$t("permission.cancel") as string,
      confirmButtonText: this.$t("permission.confirm") as string,
    })
      .then(async () => {
        //定义变量
        let res: any;

        //指定用户发送
        if (this.tags.length && this.sendParams.type === 1) {
          this.sendParams.uids = [];
          this.tags.forEach((item: any) => {
            this.sendParams.uids.push(Number(item));
          });
        } else {
          this.sendParams.uids = [];
        }

        //跳转参数
        if (this.sendParams.jump_scene == 0) {
          this.sendParams.scene_params = "";
        }

        //时间数据赋值
        if (this.sendParams.time && this.sendParams.time.length == 2) {
          this.sendParams.popup_end_time_str = this.sendParams.time[1];
          this.sendParams.popup_start_time_str = this.sendParams.time[0];
        } else {
          this.sendParams.popup_end_time_str = "";
          this.sendParams.popup_start_time_str = "";
        }

        if (this.sendParams.effectiveTime && this.sendParams.effectiveTime.length == 2) {
          this.sendParams.end_time_str = this.sendParams.effectiveTime[1];
          this.sendParams.begin_time_str = this.sendParams.effectiveTime[0];
        } else {
          this.sendParams.end_time_str = "";
          this.sendParams.begin_time_str = "";
        }

        //状态数据
        if (this.sendParams.popup_status) this.sendParams.popup_status = Number(this.sendParams.popup_status); //登录弹窗状态

        //跳转用户ID
        if (this.sendParams.jump_scene == 2) {
          this.sendParams.jump_userid = Number(this.sendParams.jump_userid);
        } else {
          this.sendParams.jump_userid = 0;
        }

        //图片参数
        this.sendParams.pics = this.sendParams.cover;

        //编辑公告
        if (this.sendParams.id) {
          //编辑公告
          res = await editOfficial({ ...this.sendParams });
        }
        //发布公告
        else {
          //发布公告
          res = await addOfficial({ ...this.sendParams });
        }

        //结果
        if (res.status === 0) {
          //显示提示
          this.sendParams.id ? this.$message.success("编辑成功") : this.$message.success("发布成功");

          //隐藏界面
          this.btnCancel();

          //获取列表
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //添加目标用户
  private addTag(type: any): void {
    //批量添加
    if (type === "more") {
      //定义变量
      let errorList: any = [];

      //数据处理
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());
          this.tags.push(item.trim());
          this.tagInputMore = ""; // 清空输入框
        });

      //显示异常提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //数据处理
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        this.tags.push(this.tagInput.trim());
        this.tagInput = ""; // 清空输入框
      }
      //异常处理
      else {
        this.$message.error("请输入正确形式的ID");
        this.tagInput = "";
      }
    }
  }

  //日期配置
  private get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
    };
  }

  //弹窗时间
  private timeChange(val: any): void {
    if (this.sendParams.time) this.sendParams.popup_end_time_str = val[1];
    if (this.sendParams.time) this.sendParams.popup_start_time_str = val[0];
  }

  //移除目标用户
  private removeTag(index: any): void {
    this.tags.splice(index, 1);
  }

  //有效时间
  private effectiveTimeChange(val: any): void {
    if (val && val.length) this.sendParams.end_time_str = val[1];
    if (this.sendParams.effectiveTime) this.sendParams.begin_time_str = val[0];
  }

  //图片图片上传
  private handlePicBeforeUploadCommon(file: any) {
    //定义配置
    const index = file.name.lastIndexOf(".");
    const isLt2M = file.size / 1024 / 1024 < 5;
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];

    //资源过大
    if (!isLt2M) {
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出5M",
        type: "warning",
        duration: 2000,
      });
      return false;
    }
    //格式不支持
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除公告图片
  private handlePicRemove(file: any, fileList: any): void {
    this.picFileList = [];
    this.sendParams.cover = "";
  }

  //移除列表图片
  private handleLPicRemove(file: any, fileList: any): void {
    this.lPicFileList = [];
    this.sendParams.image = "";
  }

  //公告图片上传成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    this.picFileList = fileList;
    this.sendParams.cover = response.data.images[0];
  }

  //列表图片上传成功
  private handleLPicUploadSuccess(response: any, file: any, fileList: any): void {
    this.lPicFileList = fileList;
    this.sendParams.image = response.data.images[0];
  }

  //处理图片添加
  private async handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: Function) {
    //数据处理
    const formData = new FormData();
    formData.append("files", file);

    //上传图片
    var config: any = {};
    config.headers = this.getHeads;
    const res = await axios.post(this.getAction, formData, config);

    //数据赋值
    let url: string = res.data.data.images[0];

    //插入图片
    Editor.insertEmbed(cursorLocation, "image", url);

    //刷新编辑器
    resetUploader();
  }

  //---------------------------- 发送名单 ----------------------------
  //定义变量
  private typeIds: any = []; //ID列表
  private typeDialog = false; //显示状态

  //隐藏发送名单
  private typeCancel(): void {
    //清空数据
    this.typeIds = [];

    //隐藏界面
    this.typeDialog = false;
  }

  //显示发送名单
  private showTypeDialog(row: any): void {
    //数据赋值
    if (row.ids && JSON.parse(row.ids).length) {
      JSON.parse(row.ids).forEach((item: any) => {
        this.typeIds.push({ id: item });
      });
    }

    //显示界面
    this.typeDialog = true;
  }

  //---------------------------- 图片加载 ----------------------------
  //定义变量
  private dialogVisible = false; //显示状态
  private dialogImageUrl: any = null; //图片地址

  //点击已上传图片
  private handlePicPreviewCommon(file: any) {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示界面
    this.dialogVisible = true;
  }

  //---------------------------- 一篇上传 ----------------------------
  //定义变量
  private getHeads: any = getHeaders(); //获取消息头
  private getAction: string = this.getApi(); //获取API地址

  //获取API地址
  private getApi(): string {
    //数据赋值
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";

    //返回API地址
    return `${prefix}${baseApi}/adm/picture/files`;
  }
}
